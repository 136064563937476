import {HOST, DEBUG} from "./constants";
import moment from 'moment';
import * as queryString from "query-string";
import {ACCESS} from "./sagas/makeRequest";
import download from "downloadjs";


export const truncChars = (text, length) => {
    if (text.length > length) {
        text = `${text.slice(0, length - 3)}...`;
    }
    return text;
};


export const getSearchParams = (path) => {
    let values = path.split('?');
    return queryString.parse(`?${values[values.length - 1]}`);
};

export const getInnerText = (text) => {
    return text.replace(/(&nbsp;|<([^>]+)>)/ig, "");
};

export const getWordCount = (text) => {
    // console.warn(text);
    return text.trim().split(/\s+/).length;
};


export const getHttpsImageUrl = (url = '') => {
    if (url.startsWith('https')) {
        return url;
    } else if (url.startsWith('http')) {
        if (DEBUG)
            return url.replace('http', 'http'); // TODO: Change this to https
        else
            return url.replace('http', 'https');
    } else {
        return HOST + url;
    }
};


export const removeDuplicateFields = (list) => {
    let dict = {};
    for (let i = 0; i < list.length; i++) {
        let obj = list[i];
        if (!dict[obj.id] || (moment(dict[obj.id].updated_at) < moment(obj.updated_at))) {
            dict[obj.id] = obj;
        }
    }
    let unsortedResults = Object.values(dict);
    return unsortedResults.sort((a, b) => a.id < b.id ? 1 : -1);
};

export const mergeFieldLists = ({list1 = [], field, remove = false}) => {
    // append list1 with list2
    let newList = [];
    if (field.field_parent_id) {
        let parentFieldFilteredList = list1.filter(o => o.id === field.field_parent_id);
        if (parentFieldFilteredList.length) {
            let parentField = parentFieldFilteredList[0];
            if (!remove) {
                parentField.field_children = removeDuplicateFields([...parentField.field_children, field]);
                return removeDuplicateFields([...list1, parentField]);
            } else {
                parentField.field_children = parentField.field_children.filter(o => o.id !== field.id);
                return removeDuplicateFields([...list1, parentField]);
            }
        }
        return list1;
    } else {
        if (!remove) {
            newList = [...list1, field];
        } else {
            newList = list1.filter(o => o.id !== field.id);
        }
        // remove duplicates with same id and different updated_at or same updated_at
        return removeDuplicateFields(newList);
    }
};


export const getBotFieldFromId = ({id, fields}) => {
    let filteredFields = fields.filter(field => field.id === id);
    if (filteredFields.length)
        return filteredFields[0];
    return undefined;
};

export const mergeConnectionWithFields = ({connection, fields}) => {
    let child = getBotFieldFromId({id: connection.parent_id, fields});
    let parent = getBotFieldFromId({id: connection.child_id, fields});

    child.successors.push(connection);
    parent.predecessors.push(connection);

    fields = fields.map(
        field => {
            if (field.id === child.id) {
                return child;
            } else if (field.id === parent.id) {
                return parent;
            } else {
                return field;
            }
        }
    );
    return fields;
};

export const removeConnectionFromFields = ({connection, fields}) => {
    let child = getBotFieldFromId({id: connection.parent_id, fields});
    let parent = getBotFieldFromId({id: connection.child_id, fields});

    child.successors.push(connection);
    parent.predecessors.push(connection);
    child.successors = child.successors.filter(o => o.id !== connection.id);
    parent.predecessors = parent.predecessors.filter(o => o.id !== connection.id);
    fields = fields.map(
        field => {
            if (field.id === child.id) {
                return child;
            } else if (field.id === parent.id) {
                return parent;
            } else {
                return field;
            }
        }
    );
    return fields;
};

export const createTreeNode = (name) => ({name,});

const getTreeNodeName = (node) => {
    if (!node.name)
        return '';
    else if (node.name.length)
        return node.name;
    return '';
};


export const expressionToTreeData = (expression, start, end, expressionId = []) => {
    expression = expression.replace(/ /g, '');
    // console.log('\n\n\n\n\nExpression : ', expression.slice(start, end));
    let stack = [];
    let startIndex = 0;
    let endIndex = expression.length;

    let i = start;
    let params = [];
    let functionStartIndex = start;
    let functionEndIndex = -1;

    while (i <= end) {
        let ch = expression[i];

        if (ch === '(') {
            stack.push(i);
            // console.log(stack);
        }

        if (ch === '(' && stack.length === 1) {
            functionEndIndex = i;
            startIndex = i + 1;
        } else if (ch === ',' && stack.length === 1) {
            endIndex = i;
            // console.log(i, ch, expression.slice(startIndex, endIndex));
            params.push([startIndex, endIndex]);
            startIndex = i + 1;
        } else if (ch === ')' && stack.length > 0 && stack.length === 1) {
            endIndex = i;
            // console.log(i, ch, expression.slice(startIndex, endIndex));
            params.push([startIndex, endIndex]);
        }

        if (ch === ')') {
            stack.pop();
            // console.log(stack);
        }
        i += 1;
    }

    let node;
    if (params.length) {
        const functionName = expression.slice(functionStartIndex, functionEndIndex);
        // console.log('Function name = ', functionName);
        node = createTreeNode(functionName);
        node.expressionId = expressionId;
        node.type = 'function';
        node.children = [];
        // console.log(params);
        for (let i = 0; i < params.length; i++) {
            node.children.push(expressionToTreeData(expression, params[i][0], params[i][1], [...expressionId, i]));
        }
    } else {
        node = createTreeNode(expression.slice(start, end));
        node.expressionId = expressionId;
        node.type = 'leaf';
    }

    console.warn('expressionToTreeData:', node, expression);
    return node;
};

export const treeDataToExpression = (treeData) => {
    let expression = getTreeNodeName(treeData);

    if (treeData.children && treeData.children.length > 0) {
        let params = [];

        treeData.children.map(
            (child, index) => {
                params.push(treeDataToExpression(child));
            }
        );
        expression += `(${params.join(',')})`;
    }

    return expression;
};

export const downloadCSVFile = (url, name, onLoadStart, onLoadEnd) => {
    let x = new XMLHttpRequest();
    x.open("GET", url, true);
    x.setRequestHeader("Authorization", "Bearer " + localStorage.getItem(ACCESS));
    x.responseType = 'blob';

    x.onloadstart = (e) => {
        onLoadStart();
    };
    x.onload = (e) => {
        if (x.status === 200) {
            download(x.response, `${name}.csv`, x.response.type);
        }
    };
    x.onloadend = (e) => {
        onLoadEnd();
    };
    x.onabort = (e) => {
        onLoadEnd();
    };
    x.onerror = (e) => {
        onLoadEnd();
    };
    x.send();
};


export const functions = {
// Boolean functions
    ISDATEBEFORE: {name: 'ISDATEBEFORE', 'params': 2},
    ISDATEAFTER: {name: 'ISDATEAFTER', 'params': 2},
    ISDATEEQUAL: {name: 'ISDATEEQUAL', 'params': undefined},
    OR: {name: 'OR', 'params': undefined},
    AND: {name: 'AND', 'params': undefined},
    EQUALTO: {name: 'EQUALTO', 'params': 2},
    NOTEQUALTO: {name: 'NOTEQUALTO', 'params': 2},
    LESSTHAN: {name: 'LESSTHAN', 'params': 2},
    GREATERTHAN: {name: 'GREATERTHAN', 'params': 2},
    LESSTHANEQUALTO: {name: 'LESSTHANEQUALTO', 'params': 2},
    GREATERTHANEQUALTO: {name: 'GREATERTHANEQUALTO', 'params': 2},
    NOT: {name: 'NOT', 'params': 1},
    ISBLANK: {name: 'ISBLANK', 'params': 1},
    READ: {name: 'READ', 'params': 1},

// Value Functions
    DIALOGFLOW: {name: 'DIALOGFLOW', 'params': 1},
    TODAY: {name: 'TODAY', 'params': 0},
    DATEDIFFINDAYS: {name: 'DATEDIFFINDAYS', 'params': 0},
    ADDDAYS: {name: 'ADDDAYS', 'params': 0},
    SUBDAYS: {name: 'SUBDAYS', 'params': 0},
    SUM: {name: 'SUM', 'params': undefined},
    SUB: {name: 'SUB', 'params': undefined},
    MUL: {name: 'MUL', 'params': undefined},
    DIV: {name: 'DIV', 'params': 2},
    ROUND: {name: 'ROUND', 'params': 2},
    ABS: {name: 'ABS', 'params': 1},
    INT: {name: 'INT', 'params': 1},
    FLOAT: {name: 'FLOAT', 'params': 1},
    STRING: {name: 'STRING', 'params': 1},
    LENGTH: {name: 'LENGTH', 'params': 1},
    COUNTWORDS: {name: 'COUNTWORDS', 'params': 1},
    IF: {name: 'IF', 'params': 3},
    VALUE: {name: 'VALUE', 'params': 1},
    SUMCHOICES: {name: 'SUMCHOICES', 'params': undefined},
    AGE: {name: 'AGE', 'params': 1},

    // Action functions | dont use actions in show_when, value expressions as then can result in unexpected updates
    UPDATEDOB: {name: 'UPDATEDOB', 'params': 1},
    UPDATENAME: {name: 'UPDATENAME', 'params': 1},
    ADDTOCART: {name: 'ADDTOCART', 'params': undefined},
    LAUNCHPAGE: {name: 'LAUNCHPAGE', 'params': 1},
    ZTABLE: {name: 'ZTABLE', 'params': 1},
    CREATEREPORT: {name: 'CREATEREPORT', 'params': 0},
};


export const getPrice = (price) => {
    return `₹${Math.round(price / 100, 10)}`;
};

export const getQueryString = (params) => {
    return Object.keys(params).map(key => key + '=' + params[key]).join('&');
};

export const downloadCSVFromJson = (filename, arrayOfArray) => {
    // convert JSON to CSV
   let csv = arrayOfArray.join('\r\n');
    // Create link and download
    let link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const getStorageItem = (key, defaultValue = null) => {
    try {
        return localStorage.getItem(key) || defaultValue
    } catch (e) {
        return defaultValue;
    }
};


export const setStorageItem = (key, value) => {
    try {
        return localStorage.setItem(key, value);
    } catch (e) {
        return null;
    }
};


export const removeStorageItem = (key) => {
    try {
        return localStorage.removeItem(key);
    } catch (e) {
        return null;
    }
};
