import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';



export default class CardContainer extends PureComponent {

    static propTypes = {
        style: PropTypes.object,
        className: PropTypes.string,
    };

    static defaultProps = {
        style: {},
        className: '',
    };

    render() {
        return (
            <div className={`card-container ${this.props.className}`} style={this.props.style}>
                {this.props.children}
            </div>
        )
    }
}
