export const FETCH_SALES_DATAS = 'FETCH_SALES_DATAS';
export const FETCH_SALES_DATAS_RESULT = 'FETCH_SALES_DATAS_RESULT';
export const FETCH_SALES_DATAS_ERROR = 'FETCH_SALES_DATAS_ERROR';
export const FETCH_SALES_DATAS_RETRY = 'FETCH_SALES_DATAS_RETRY';

export const FETCH_SALES_DATA = 'FETCH_SALES_DATA';
export const FETCH_SALES_DATA_RESULT = 'FETCH_SALES_DATA_RESULT';
export const FETCH_SALES_DATA_ERROR = 'FETCH_SALES_DATA_ERROR';
export const FETCH_SALES_DATA_RETRY = 'FETCH_SALES_DATA_RETRY';

export const CREATE_SALES_DATA = 'CREATE_SALES_DATA';
export const CREATE_SALES_DATA_RESULT = 'CREATE_SALES_DATA_RESULT';
export const CREATE_SALES_DATA_ERROR = 'CREATE_SALES_DATA_ERROR';
export const CREATE_SALES_DATA_RETRY = 'CREATE_SALES_DATA_RETRY';

export const UPDATE_SALES_DATA = 'UPDATE_SALES_DATA';
export const UPDATE_SALES_DATA_RESULT = 'UPDATE_SALES_DATA_RESULT';
export const UPDATE_SALES_DATA_ERROR = 'UPDATE_SALES_DATA_ERROR';
export const UPDATE_SALES_DATA_RETRY = 'UPDATE_SALES_DATA_RETRY';

export const DELETE_SALES_DATA = 'DELETE_SALES_DATA';
export const DELETE_SALES_DATA_RESULT = 'DELETE_SALES_DATA_RESULT';
export const DELETE_SALES_DATA_ERROR = 'DELETE_SALES_DATA_ERROR';
export const DELETE_SALES_DATA_RETRY = 'DELETE_SALES_DATA_RETRY';


export const fetchSalesDatas = (data) => ({
    type: FETCH_SALES_DATAS,
    data
});

export const fetchSalesData = (data) => ({
    type: FETCH_SALES_DATA,
    data
});

export const createSalesData = (data) => ({
    type: CREATE_SALES_DATA,
    data
});

export const updateSalesData = (data) => ({
    type: UPDATE_SALES_DATA,
    data
});

export const deleteSalesData = (data) => ({
    type: DELETE_SALES_DATA,
    data
});