export const FETCH_REPORT_SHEETS = 'FETCH_REPORT_SHEETS';
export const FETCH_REPORT_SHEETS_RESULT = 'FETCH_REPORT_SHEETS_RESULT';
export const FETCH_REPORT_SHEETS_ERROR = 'FETCH_REPORT_SHEETS_ERROR';
export const FETCH_REPORT_SHEETS_RETRY = 'FETCH_REPORT_SHEETS_RETRY';

export const FETCH_REPORT_SHEET = 'FETCH_REPORT_SHEET';
export const FETCH_REPORT_SHEET_RESULT = 'FETCH_REPORT_SHEET_RESULT';
export const FETCH_REPORT_SHEET_ERROR = 'FETCH_REPORT_SHEET_ERROR';
export const FETCH_REPORT_SHEET_RETRY = 'FETCH_REPORT_SHEET_RETRY';

export const fetchReportSheets = () => ({
    type: FETCH_REPORT_SHEETS,
});


export const fetchReportSheet = (data) => ({
    type: FETCH_REPORT_SHEET,
    data
});