import {HOST} from '../constants'
import makeRequest from "./makeRequest";
import {getQueryString} from "../utils";

export {HOST};


export const jsonToForm = (data) => {
    const form = new FormData();
    const keys = Object.keys(data);
    const values = Object.values(data);

    keys.map((key, i) => {
        form.append(key, values[i])
    });
    return form;
};


export const addFileToForm = ({form = new FormData(), key, file}) => {
    // form.append(key, {
    //     uri: file.uri,
    //     name: file.name,
    //     type: file.type ? file.type : 'image/jpg',
    // });
    form.append(key, file, file.name);
    return form;
};


export default {
    accounts: {
        postUserLogin: data => (makeRequest(
            `${HOST}/api/v1/accounts/login/username/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        getUser: data => (makeRequest(
            `${HOST}/api/v1/accounts/profile/`,
            {method: 'get'}
        )),
        patchUser: data => (makeRequest(
            `${HOST}/api/v1/accounts/profile/`,
            {method: 'PATCH', body: jsonToForm(data)}
        )),
        requestOtp: data => (makeRequest(
            `${HOST}/api/v1/accounts/otp/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        tokenVerify: data => (makeRequest(
            `${HOST}/api/v1/accounts/token/verify/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        tokenRefresh: data => (fetch(
            `${HOST}/api/v1/accounts/token/refresh/`,
            {method: 'post', body: jsonToForm(data)}
        )),
    },
    dashboard: {
        reportsheets: {
            list: (data) => makeRequest(`${HOST}/api/v1/analytics/reportsheets/`,),
            get: ({id, page = 1, q = ''}) => makeRequest(`${HOST}/api/v1/analytics/reportsheets/${id}/?q=${q}&page=${page}`),
        },
        reportsheetsfields: {
            list: ({reportsheet_id}) => makeRequest(`${HOST}/api/v1/analytics/reportsheets/${reportsheet_id}/field_ids/`,),
            get: data => makeRequest(
                `${HOST}/api/v1/analytics/reportsheets/${data.reportsheet_id}/field_percentage/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
            ),
        }
    },
    analytics: {
        dashboard: () => makeRequest(`${HOST}/api/v1/analytics/dashboard/`,),
        orderstatuscount: (data) => makeRequest(`${HOST}/api/v1/analytics/orderstatuscount/?${getQueryString(data)}`,
            {method: 'GET'}),
    },
    salesdata: {
        create: (data) => makeRequest(`${HOST}/api/v1/analytics/salesdata/`,
            {method: 'POST', body: JSON.stringify(data), bodyType: 'json',}
        ),
        list: ({page = 1, search}) => makeRequest(`${HOST}/api/v1/analytics/salesdata/?page=${page}&q=${search}`),
        get: ({id}) => makeRequest(`${HOST}/api/v1/analytics/salesdata/${id}/`),
        remove: ({id}) => makeRequest(`${HOST}/api/v1/analytics/salesdata/${id}/`, {method: 'DELETE'}),
        update: (data) => makeRequest(`${HOST}/api/v1/analytics/salesdata/${data.id}/`,
            {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json',}),
    },

}
