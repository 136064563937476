import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {Modal, Form, Col, Button} from 'react-bootstrap';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {fetchSalesData, createSalesData, updateSalesData, deleteSalesData} from "../../../actions/salesdata/salesdata";
import {CHANNEL_TYPES, SELLER_NAMES} from './data'


class SalesDataEditCreateModal extends React.Component {

    static propTypes = {
        sales: PropTypes.object,
        show: PropTypes.bool,
        fetchSalesData: PropTypes.func,
        createSalesData: PropTypes.func,
        updateSalesData: PropTypes.func,
        deleteSalesData: PropTypes.func,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        sales: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            sales: {seller_name: 'qaaf', channel: 'amazon', ...props.sales},
            message: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {detail, isFetching, isUpdating, isCreating, isDeleting, message, error, retry} = this.props.salesdata;

        if (this.props.show && !prevProps.show) {
            this.props.fetchSalesData();
            this.setState({
                sales: {seller_name: 'qaaf', channel: 'amazon', ...this.props.sales},
                message: null,
            });
        }

        if ((!isUpdating && prevProps.salesdata.isUpdating && (error || retry)) ||
            (!isCreating && prevProps.salesdata.isCreating && (error || retry))) {
            this.setState({message});
        }
    }

    handleCreateClick = () => {
        let {sales} = this.state;
        if (!!sales.id) {
            this.props.updateSalesData(sales);
        } else {
            this.props.createSalesData(sales);
        }
    };

    handleDelete = () => {
        let {sales} = this.state;
        if (!!sales.id) {
            this.props.deleteSalesData(sales);
        }
    }

    render() {
        let {show, handleClose} = this.props;
        let {
            sales: {channel, seller_name, sales_count, sales_amount, timestamp},
            sales, message,
        } = this.state;

        return (
            <Modal size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sales Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} controlId="items_select">
                            <Form.Label>Seller Type</Form.Label>
                            <Select options={SELLER_NAMES}
                                    value={SELLER_NAMES.filter(name => name.value === seller_name).map(name => (name))}
                                    onChange={(name) => this.setState({
                                        sales: {
                                            ...sales,
                                            seller_name: name.value,
                                        }
                                    })}/>
                            <Form.Control type="hidden"
                                          isInvalid={!!message && !!message.seller_name}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.seller_name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="channel">
                            <Form.Label>Channel</Form.Label>
                            <Select options={CHANNEL_TYPES}
                                    value={CHANNEL_TYPES.filter(type => type.value === channel).map(type => (type))}
                                    onChange={(type) => this.setState({
                                        sales: {
                                            ...sales,
                                            channel: type.value,
                                        }
                                    })}/>
                            <Form.Control type="hidden"
                                          isInvalid={!!message && !!message.channel}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.channel}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="sales_count">
                            <Form.Label>Sales count</Form.Label>
                            <Form.Control type="number" placeholder="Sales count"
                                          value={sales_count || ''}
                                          isInvalid={!!message && !!message.sales_count}
                                          onChange={(e) => this.setState({
                                              sales: {
                                                  ...sales,
                                                  sales_count: e.target.value
                                              }
                                          })}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.sales_count}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="sales_amount">
                            <Form.Label>Sales amount</Form.Label>
                            <Form.Control type="number" placeholder="Sales amount"
                                          value={sales_amount || ''}
                                          isInvalid={!!message && !!message.sales_amount}
                                          onChange={(e) => this.setState({
                                              sales: {
                                                  ...sales,
                                                  sales_amount: e.target.value
                                              }
                                          })}/>
                            <Form.Text>in paisa</Form.Text>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.sales_amount}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="timestamp">
                            <Form.Label>Time stamp</Form.Label>
                            <DatePicker showYearDropdown showMonthDropdown
                                        className='form-control'
                                        placeholderText='Time stamp'
                                        dateFormat="dd MMM yyyy"
                                        selected={!!timestamp ? new Date(timestamp) : ''}
                                        onChange={(date) => this.setState({
                                            sales: {
                                                ...sales,
                                                timestamp: moment(date).format(),
                                            }
                                        })}
                            />
                            <Form.Control type="hidden"
                                          isInvalid={!!message && !!message.timestamp}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.timestamp}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>Close</Button>
                    {!!sales.id &&
                    <Button variant="danger" onClick={this.handleDelete}>Delete</Button>
                    }
                    <Button variant="primary" onClick={this.handleCreateClick}>
                        {!!sales.id ? 'Update' : 'Create'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    salesdata: state.salesdata.salesdata,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchSalesData,
    createSalesData,
    updateSalesData,
    deleteSalesData,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(SalesDataEditCreateModal);