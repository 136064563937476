import {takeEvery, all} from 'redux-saga/effects';

import accounts from './accounts';
import dashboard from './dashboard';
import salesdata from './salesdata';


export default function* sagas() {
    yield all([
        ...accounts,
        ...dashboard,
        ...salesdata,
    ]);
}
