import {takeEvery} from 'redux-saga/effects';

import {USER_LOGIN, } from '../../actions/accounts/login';
import {UPDATE_USER_PROFILE, FETCH_USER_PROFILE} from '../../actions/accounts/profile';
import {updateUserProfile, fetchUserProfile} from './profile';
import {userLogin} from "./login";


export default [
    takeEvery(USER_LOGIN, userLogin),
    takeEvery(UPDATE_USER_PROFILE, updateUserProfile),
    takeEvery(FETCH_USER_PROFILE, fetchUserProfile),
];
