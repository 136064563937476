import {takeEvery} from 'redux-saga/effects';

import {
    FETCH_SALES_DATAS,
    FETCH_SALES_DATA,
    CREATE_SALES_DATA,
    UPDATE_SALES_DATA,
    DELETE_SALES_DATA
} from '../../actions/salesdata/salesdata';
import {fetchSalesDatas, fetchSalesData, createSalesData, updateSalesData, deleteSalesData} from "./salesdata";


export default [
    takeEvery(FETCH_SALES_DATAS, fetchSalesDatas),
    takeEvery(FETCH_SALES_DATA, fetchSalesData),
    takeEvery(CREATE_SALES_DATA, createSalesData),
    takeEvery(UPDATE_SALES_DATA, updateSalesData),
    takeEvery(DELETE_SALES_DATA, deleteSalesData),
];
