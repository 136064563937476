import {call, put} from 'redux-saga/effects';
import {
    FETCH_SALES_DATAS,
    FETCH_SALES_DATAS_RESULT,
    FETCH_SALES_DATAS_ERROR,
    FETCH_SALES_DATAS_RETRY,

    FETCH_SALES_DATA,
    FETCH_SALES_DATA_RESULT,
    FETCH_SALES_DATA_ERROR,
    FETCH_SALES_DATA_RETRY,

    CREATE_SALES_DATA,
    CREATE_SALES_DATA_RESULT,
    CREATE_SALES_DATA_ERROR,
    CREATE_SALES_DATA_RETRY,

    UPDATE_SALES_DATA,
    UPDATE_SALES_DATA_RESULT,
    UPDATE_SALES_DATA_ERROR,
    UPDATE_SALES_DATA_RETRY,

    DELETE_SALES_DATA,
    DELETE_SALES_DATA_RESULT,
    DELETE_SALES_DATA_ERROR,
    DELETE_SALES_DATA_RETRY,
} from '../../actions/salesdata/salesdata';
import api from '../api';
import {toast} from "react-toastify";


export function* fetchSalesDatas(action) {
    try {
        const response = yield call(api.salesdata.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchSalesDatas', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_SALES_DATAS_RESULT, data: result});
        } else {
            if (result.message)
                toast(result.message,);
            yield put({type: FETCH_SALES_DATAS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_SALES_DATAS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchSalesData(action) {
    try {
        const response = yield call(api.salesdata.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchSalesData', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_SALES_DATA_RESULT, data: result});
        } else {
            yield put({type: FETCH_SALES_DATA_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_SALES_DATA_RETRY, retry: true, message: e.message});
    }
}


export function* createSalesData(action) {
    try {
        const response = yield call(api.salesdata.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createSalesData', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_SALES_DATA_RESULT, data: result});
        } else {
            yield put({type: CREATE_SALES_DATA_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_SALES_DATA_RETRY, retry: true, message: e.message});
    }
}


export function* updateSalesData(action) {
    try {
        const response = yield call(api.salesdata.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateSalesData', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_SALES_DATA_RESULT, data: result});
        } else {
            yield put({type: UPDATE_SALES_DATA_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_SALES_DATA_RETRY, retry: true, message: e.message});
    }
}


export function* deleteSalesData(action) {
    try {
        const response = yield call(api.salesdata.remove, action.data);
        console.warn('SAGA:deleteSalesData', action);

        if (response.status === 204) {
            yield put({type: DELETE_SALES_DATA_RESULT});
        } else {
            yield put({type: DELETE_SALES_DATA_ERROR, error: true});
        }
    } catch (e) {
        yield put({type: DELETE_SALES_DATA_RETRY, retry: true, message: e.message});
    }
}

