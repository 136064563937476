import {takeEvery} from 'redux-saga/effects';

import {FETCH_REPORT_SHEETS ,FETCH_REPORT_SHEET} from '../../actions/dashboard/reportsheets';
import {fetchReportSheets, fetchReportSheet} from "./reportsheets";

import {FETCH_REPORT_SHEETS_FIELDS, FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE} from '../../actions/dashboard/reportsheetsfields';
import {fetchReportSheetsFields, fetchReportSheetsFieldsPercentage} from "./reportsheetsfields";


export default [
    takeEvery(FETCH_REPORT_SHEETS, fetchReportSheets),
    takeEvery(FETCH_REPORT_SHEET, fetchReportSheet),

    takeEvery(FETCH_REPORT_SHEETS_FIELDS, fetchReportSheetsFields),
    takeEvery(FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE, fetchReportSheetsFieldsPercentage),
];
