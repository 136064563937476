import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import * as PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import {fetchReportSheetsFields, fetchReportSheetsFieldsPercentage} from '../../actions/dashboard/reportsheetsfields'
import Table from "react-bootstrap/Table";
import Icon from "../../components/others/Icon";
import {BOOL, CHOICE} from "../../constants";


const boolOptions = [{label: 'True', value: 'true'}, {label: 'False', value: 'false'}];


class DashboardPieChart extends React.Component {

    static propTypes = {
        fetchReportSheetsFields: PropTypes.func,
        reportsheet_id: PropTypes.number,
    };

    static defaultProps = {
        reportsheet_id: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedFields: [],
            newField: {
                field_id: '',
                type: '',
                value: ''
            },
        };
    }


    componentDidMount() {
        const {reportsheet_id, date} = this.props;
        this.props.fetchReportSheetsFields({reportsheet_id, date});
    }

    handleFieldsPercentage = () => {
        const {selectedFields} = this.state;
        const {reportsheet_id, date} = this.props;
        this.props.fetchReportSheetsFieldsPercentage({field_ids: selectedFields, reportsheet_id, date});
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        let {reportsheet_id, date} = this.props;

        if (prevProps.reportsheet_id !== reportsheet_id || prevProps.date !== date) {
            this.props.fetchReportSheetsFields({reportsheet_id, date});
        }
    }

    handleAdd = () => {
        const {selectedFields, newField} = this.state;
        if (!!newField.field_id && !!newField.value && !!newField.value) {
            this.setState({
                selectedFields: [
                    {
                        field_id: newField.field_id,
                        type: newField.type,
                        value: newField.value
                    },
                    ...selectedFields,
                ],
                newField: {
                    field_id: '',
                    type: '',
                    value: ''
                }
            }, () => {
                this.handleFieldsPercentage()
            })
        }
    };

    handleFieldIdSelectChange = (field_id, type, index) => {
        let {selectedFields} = this.state;

        selectedFields[index] = {
            ...selectedFields[index],
            field_id,
            type,
        };

        this.setState({selectedFields})
    };

    handleFieldValueChange = (field, index) => {
        let {selectedFields} = this.state;

        selectedFields[index] = {
            ...selectedFields[index],
            value: field
        };

        this.setState({selectedFields})
    };

    handleNewFieldChange = (values) => {
        let {newField} = this.state;
        this.setState({
            newField: {
                ...newField,
                ...values,
            },
        })
    };

    handelClear = (index) => {
        let {selectedFields} = this.state;
        selectedFields.splice(index, 1);
        this.setState({
            selectedFields
        }, () => {
            this.handleFieldsPercentage()
        })
    };

    render() {

        const {reportsheetsfields, reportsheetsfields: {detail, list}} = this.props;
        const {newField, selectedFields} = this.state;

        let all_field_id_types = reportsheetsfields.list;
        let all_bool_field_ids = reportsheetsfields.list.filter(f => f.type === BOOL);
        let fieldIdOptions = all_field_id_types.map(({field_id, type}) => ({label: field_id, value: [field_id, type]}));
        let boolFieldIdOptions = all_bool_field_ids.map(({field_id, type}) => ({label: field_id, value: [field_id, type]}));

        let filteredFieldIdValues = all_field_id_types.filter(({field_id, type}) => field_id === newField.field_id);
        let fieldIdValues = filteredFieldIdValues.map(({field_id, type}) => ({
            label: field_id,
            value: [field_id, type]
        }));

        return (
            <div className="row py-3 px-2">
                <div className="col-12">
                    <Form>
                        <Button variant="success" className="float-right mb-2" size="sm"
                                disabled={!all_field_id_types.length}
                                onClick={() => this.handleFieldsPercentage()}>Update</Button>
                        <Table className='mb-0' striped bordered hover>
                            <thead>
                            <tr>
                                <th>Field Id</th>
                                <th>Value</th>
                                <th>Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="w-50">
                                    <Form.Group controlId="formSelect">
                                        <Select options={fieldIdOptions}
                                                placeholder="Select field id"
                                                value={fieldIdValues}
                                                onChange={(f) => {
                                                    this.handleNewFieldChange({
                                                        field_id: f.value[0],
                                                        type: f.value[1],
                                                    });
                                                }}/>
                                    </Form.Group>
                                </td>
                                <td className="w-25">
                                    <Form.Group controlId="formValue" style={{minWidth: 150}}>
                                        {
                                            newField.type === BOOL &&
                                            <Select
                                                options={boolOptions}
                                                placeholder="True or False"
                                                value={boolOptions.filter(({value}) => value === newField.value)}
                                                onChange={({value}) => {
                                                    this.handleNewFieldChange({value});
                                                }}/>
                                        }
                                        {
                                            newField.type === CHOICE &&
                                            <Select
                                                options={boolFieldIdOptions}
                                                placeholder="select options"
                                                value={boolFieldIdOptions.filter(({label}) => label === newField.value)}
                                                onChange={({label}) => {
                                                    this.handleNewFieldChange({value: label});
                                                }}/>
                                        }
                                        {
                                            newField.type !== BOOL && newField.type !== CHOICE &&
                                            <Form.Control type="text" placeholder="Enter Value"
                                                          value={newField.value}
                                                          onChange={(e) => {
                                                              this.handleNewFieldChange({
                                                                  value: e.target.value
                                                              });
                                                          }}/>
                                        }
                                    </Form.Group>
                                </td>
                                <td colSpan="2">
                                    <Button variant={'success'} onClick={this.handleAdd} size="sm">
                                        <Icon name={'add'} color={'#FFFFF'} size={"20px"}/>
                                    </Button>
                                </td>
                            </tr>

                            {
                                selectedFields.map((field, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="w-50">
                                                <Form.Group controlId="formSelect">
                                                    <Select
                                                        options={fieldIdOptions}
                                                        placeholder="Select Report Sheet"
                                                        value={fieldIdOptions.filter(({label}) => label === field.field_id)}
                                                        onChange={({value}) => {
                                                            this.handleFieldIdSelectChange(value[0], value[1], index)
                                                        }}/>
                                                </Form.Group>
                                            </td>
                                            <td>
                                                <Form.Group controlId="formValue">
                                                    {
                                                        field.type === BOOL &&
                                                        <Select
                                                            options={boolOptions}
                                                            placeholder="True or False"
                                                            value={boolOptions.filter(({value}) => value === field.value)}
                                                            onChange={({value}) => {
                                                                this.handleFieldValueChange(value, index)
                                                            }}/>
                                                    }
                                                    {
                                                        field.type !== BOOL &&
                                                        <Form.Control type="text" placeholder="Enter Value"
                                                                      value={field.value}
                                                                      onChange={(e) => {
                                                                          this.handleFieldValueChange(e.target.value, index)
                                                                      }}/>
                                                    }
                                                </Form.Group>
                                            </td>
                                            <td>
                                                {!!detail.field_ids_count && !!detail.field_ids_count.length &&
                                                detail.field_ids_count[index]
                                                }
                                            </td>
                                            <td>
                                                <Button variant={'danger'} onClick={() => this.handelClear(index)}
                                                        size="sm">
                                                    <Icon name={'close'} color={'#FFFFF'} size={'20px'}/></Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            {!!detail.total_count &&
                            <tr>
                                <th colSpan="2">AND</th>
                                <th colSpan="2">{detail.and_field_ids_count}</th>
                            </tr>
                            }
                            {!!detail.total_count &&
                            <tr>
                                <th colSpan="2">Total</th>
                                <th colSpan="2">{detail.total_count}</th>
                            </tr>
                            }
                            </tbody>
                        </Table>
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reportsheetsfields: state.dashboard.reportsheetsfields,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchReportSheetsFields,
    fetchReportSheetsFieldsPercentage,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(DashboardPieChart);
