import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import api from "../../sagas/api";
import Table from "react-bootstrap/Table";
import {downloadCSVFromJson, getPrice} from "../../utils";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Button} from "react-bootstrap";
import SocketIOClient from "socket.io-client";
import {NODE_SERVER_HOST} from "../../constants";


class OrdersDashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dashboard: null,
            orderStatusCount: {},
            date: {
                from_date: moment().subtract(7, "day").format('YYYY-MM-DDTHH:mm:ss'),
                to_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
            }
        }
    }

    static propTypes = {};


    socket = SocketIOClient(NODE_SERVER_HOST, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99999,
    });

    componentDidMount() {
        this.handleCheckOrderStatus();
        this.handleOrderStatusCount();
        this.setSocketListeners();
    }

    componentWillUnmount() {
    }

    handlePageSocketData = (data) => {
        setTimeout(() => {
            this.handleCheckOrderStatus();
            this.handleOrderStatusCount();
            this.handlePlayOrderSound();
        }, 2000)
    };

    setSocketListeners = () => {
        this.socket.on(`ANALYTICS:ORDERS`, this.handlePageSocketData);
        this.socket.on('reconnect', (attemptNumber) => {
            this.setState({connected: true});
        });
        this.socket.on('disconnect', (reason) => {
            if (reason === 'io server disconnect') {
                this.socket.connect();
            }
            this.setState({connected: false});
        });
    };

    handleCheckOrderStatus = async () => {
        try {
            let response = await api.analytics.dashboard();
            let result = await response.json();
            this.setState({dashboard: result});
        } catch (e) {
        }
    };

    handleOrderStatusCount = async () => {
        let {date} = this.state;
        date = {
            from_date: moment(date.from_date).format('YYYY-MM-DDT00:00:00'),
            to_date: moment(date.to_date).format('YYYY-MM-DDT23:59:59'),
        };
        try {
            let response = await api.analytics.orderstatuscount(date);
            let result = await response.json();
            this.setState({orderStatusCount: result});
        } catch (e) {
        }
    };

    handleOrderStatusCountDownload = () => {
        let {orderStatusCount, date} = this.state;
        let fileName = moment(date.from_date).format('YYYYMMDD') + '-' + moment(date.to_date).format('YYYYMMDD');
        downloadCSVFromJson(fileName, [orderStatusCount.head, ...orderStatusCount.body]);
    };

    handlePlayOrderSound = () => {
        let audio = new Audio('ordersound.mp3');
        audio.play();
    };

    render() {
        const {dashboard, orderStatusCount, date} = this.state;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Orders Dashboard'}/>
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-12">
                            <Table responsive className="order-dashboard-table" style={{fontSize: 30}}>
                                <thead>
                                <tr>
                                    <th>-</th>
                                    <th>Count</th>
                                    <th>Amount</th>
                                    <th>Pre-paid Count</th>
                                    <th>Pre-paid Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{minWidth: 200}}>Today</td>
                                    <td>{!!dashboard && dashboard.daily_count}</td>
                                    <td>{!!dashboard && getPrice(dashboard.daily_revenue)}</td>
                                    <td>{!!dashboard && dashboard.daily_prepaid_count}</td>
                                    <td>{!!dashboard && getPrice(dashboard.daily_prepaid_revenue)}</td>
                                </tr>
                                <tr>
                                    <td style={{minWidth: 200}}>Yesterday</td>
                                    <td>{!!dashboard && dashboard.yesterday_count}</td>
                                    <td>{!!dashboard && getPrice(dashboard.yesterday_revenue)}</td>
                                    <td>{!!dashboard && dashboard.yesterday_prepaid_count}</td>
                                    <td>{!!dashboard && getPrice(dashboard.yesterday_prepaid_revenue)}</td>
                                </tr>
                                <tr>
                                    <td style={{minWidth: 200}}>Last 7 days</td>
                                    <td>{!!dashboard && dashboard.weekly_count}</td>
                                    <td>{!!dashboard && getPrice(dashboard.weekly_revenue)}</td>
                                    <td>{!!dashboard && dashboard.weekly_prepaid_count}</td>
                                    <td>{!!dashboard && getPrice(dashboard.weekly_prepaid_revenue)}</td>
                                </tr>
                                <tr>
                                    <td style={{minWidth: 200}}>Last 30 days</td>
                                    <td>{!!dashboard && dashboard.monthly_count}</td>
                                    <td>{!!dashboard && getPrice(dashboard.monthly_revenue)}</td>
                                    <td>{!!dashboard && dashboard.monthly_prepaid_count}</td>
                                    <td>{!!dashboard && getPrice(dashboard.monthly_prepaid_revenue)}</td>
                                </tr>
                                <tr>
                                    <td style={{minWidth: 200}}>Total</td>
                                    <td>{!!dashboard && dashboard.total_count}</td>
                                    <td>{!!dashboard && getPrice(dashboard.total_revenue)}</td>
                                    <td>{!!dashboard && dashboard.total_prepaid_count}</td>
                                    <td>{!!dashboard && getPrice(dashboard.total_prepaid_revenue)}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <hr/>
                    <br/>

                    <div className="row mt-5">
                        <div className="col-12 col-md-6">
                            <InputGroup>
                                <Form.Group className="m-0" controlId="from_date">
                                    <DatePicker showYearDropdown showMonthDropdown
                                                className='form-control'
                                                placeholderText='From Date'
                                                dateFormat="dd MMM yyyy"
                                                selected={!!date.from_date ? new Date(date.from_date) : ''}
                                                onChange={(d) => this.setState({
                                                    date: {
                                                        ...date,
                                                        from_date: moment(d).format('YYYY-MM-DDTHH:mm:ss'),
                                                    }
                                                })}
                                    />
                                </Form.Group>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>To</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Group className="m-0" controlId="to_date">
                                    <DatePicker showYearDropdown showMonthDropdown
                                                className='form-control'
                                                placeholderText='To Date'
                                                dateFormat="dd MMM yyyy"
                                                selected={!!date.to_date ? new Date(date.to_date) : ''}
                                                minDate={new Date(date.from_date)}
                                                onChange={(d) => this.setState({
                                                    date: {
                                                        ...date,
                                                        to_date: moment(d).format('YYYY-MM-DDTHH:mm:ss'),
                                                    }
                                                })}
                                    />
                                </Form.Group>
                                <Button variant="success" onClick={this.handleOrderStatusCount}>Update</Button>
                            </InputGroup>
                        </div>
                        <div className="col-12 col-md-6 text-right">
                            <Button variant="primary" onClick={this.handleOrderStatusCountDownload}>Download</Button>
                        </div>

                        <div className="col-12 mt-4">
                            <Table responsive className="order-dashboard-table">
                                <thead>
                                <tr>
                                    {!!orderStatusCount.head &&
                                    orderStatusCount.head.map(countHeading => (
                                        <th key={countHeading}>{countHeading}</th>
                                    ))
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {!!orderStatusCount.body &&
                                orderStatusCount.body.map((sku, index) => (
                                    <tr key={index + 1}>
                                        {!!sku &&
                                        sku.map((count, countIndex) => (
                                            <td key={countIndex + 1}>{count}</td>
                                        ))
                                        }
                                    </tr>
                                ))
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({});

const matchDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(OrdersDashboard);
