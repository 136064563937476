import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {fetchReportSheets, fetchReportSheet} from "../../actions/dashboard/reportsheets";

import Icon from "../../components/others/Icon";
import Pagination from "react-bootstrap/Pagination";
import InputGroup from "react-bootstrap/InputGroup";
import CardContainer from "../../components/containers/CardContainer";
import {downloadCSVFile, getSearchParams} from "../../utils";
import {HOST, scrollToTop} from "../../constants";
import Spinner from "react-bootstrap/Spinner";
import DashboardPieChart from "./DashboardPieChart";
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class Dashboard extends React.Component {

    static propTypes = {
        reportsheets: PropTypes.object,
        fetchReportSheets: PropTypes.func,
        fetchReportSheet: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {page = 1, q = ''} = getSearchParams(search);


        this.state = {
            reportsheet_id: null,
            search: q,
            searchQuery: q,
            page,
            csvDownload: false,
            date: {
                from: '',
                to: '',
            }
        };
    }


    componentDidMount() {
        this.props.fetchReportSheets();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {reportsheets, reportsheets: {isFetchingList, isFetching, error, retry}} = this.props;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);
        const {reportsheet_id, search, page} = this.state;

        if (!isFetching && prevProps.reportsheets.isFetching && !error && !retry) {
            scrollToTop();
        }

        if (!isFetchingList && prevProps.reportsheets.isFetchingList && !error && !retry) {
            this.setState({
                reportsheet_id: reportsheets.list.length && reportsheets.list[0].id
            })
        }

        if (reportsheet_id !== prevState.reportsheet_id || page !== prevState.page || search !== prevState.search) {
            this.props.fetchReportSheet({q: search, page, id: reportsheet_id});
        }

        if (prevQueryParams.q !== queryParams.q || prevQueryParams.page !== queryParams.page) {
            this.setState({
                search: queryParams.q || '',
                searchQuery: queryParams.q || '',
                page: parseInt(queryParams.page || "1"),
            });
        }

    }

    onLoadStart = () => {
        this.setState({csvDownload: true})
    };

    onLoadEnd = () => {
        this.setState({csvDownload: false})
    };

    handleSearchClick = (e) => {
        e && e.preventDefault();
        let {searchQuery} = this.state;
        this.setState({page: 1, search: searchQuery});
        this.props.history.push(`/?q=${searchQuery}&page=${1}`);
    };

    handlePageClick = (page) => {
        let {search} = this.state;
        this.setState({page});
        this.props.history.push(`/?q=${search}&page=${page}`);
    };

    renderPagination = () => {
        const {reportsheets: {detail}} = this.props;
        let {count} = detail;
        let items = [];
        for (let i = 0; i < Math.ceil(count / 10); i++) {
            items.push(<Pagination.Item key={i + 1} active={this.state.page == (i + 1)}
                                        onClick={() => this.handlePageClick(i + 1)}>{i + 1}</Pagination.Item>);
        }
        return <Pagination>{items}</Pagination>;
    };

    render() {
        let {reportsheet_id, searchQuery, csvDownload, date} = this.state;
        const {reportsheets, reportsheets: {detail, isFetching}} = this.props;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Quiz Dashboard'}/>
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-12 col-md-6">
                            <Form.Group controlId="fieldText">
                                <Select options={reportsheets.list.map(r => ({label: r.name, value: r.id}))}
                                        placeholder="Select Report Sheet"
                                        value={reportsheets.list.filter(r => r.id === reportsheet_id).map(r => ({
                                            label: r.name,
                                            value: r.id
                                        }))}
                                        onChange={(r) => {
                                            this.setState({
                                                reportsheet_id: r.value,
                                            })
                                        }}/>
                            </Form.Group>
                        </div>
                        <div className="col-12 col-md-6">
                            <InputGroup>
                                <Form.Group className="m-0" controlId="from_date">
                                    <DatePicker showYearDropdown showMonthDropdown
                                                className='form-control'
                                                placeholderText='From Date'
                                                dateFormat="dd MMM yyyy"
                                                selected={!!date.from ? new Date(date.from) : ''}
                                                onChange={(d) => this.setState({
                                                    date: {
                                                        ...date,
                                                        from: moment(d).format(),
                                                    }
                                                })}
                                    />
                                </Form.Group>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>To</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Group className="m-0" controlId="to_date">
                                    <DatePicker showYearDropdown showMonthDropdown
                                                className='form-control'
                                                placeholderText='To Date'
                                                dateFormat="dd MMM yyyy"
                                                selected={!!date.to ? new Date(date.to) : ''}
                                                minDate={new Date(date.from)}
                                                onChange={(d) => this.setState({
                                                    date: {
                                                        ...date,
                                                        to: moment(d).format(),
                                                    }
                                                })}
                                    />
                                </Form.Group>
                            </InputGroup>
                        </div>
                    </div>

                    <CardContainer className="mb-5">
                        {!!this.state.reportsheet_id &&
                        <DashboardPieChart reportsheet_id={this.state.reportsheet_id} date={this.state.date}/>}
                    </CardContainer>

                    <div className="row pt-3">
                        <div className="col-12 mb-4">
                            <CardContainer className="px-2 pt-3">
                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <Form onSubmit={this.handleSearchClick}>
                                            <InputGroup>
                                                <Form.Control type="text"
                                                              value={searchQuery}
                                                              placeholder="Search Report"
                                                              onChange={(e) => this.setState({searchQuery: e.target.value})}/>
                                                <InputGroup.Append>
                                                    <Button type="submit" variant="success" size="sm">
                                                        {isFetching ?
                                                            <Spinner size="sm" animation="border"/> :
                                                            "Search"
                                                        }
                                                    </Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form>
                                    </div>
                                    <div className="col-12 col-md-6 text-right">
                                        <Button variant="success" size="sm" style={{minWidth: '100px'}}
                                                onClick={() => downloadCSVFile(`${HOST}/api/v1/analytics/reportsheets/${reportsheet_id}/csv`,
                                                    'ReportSheet', this.onLoadStart, this.onLoadEnd)}>
                                            {csvDownload ? <Spinner size="sm" animation="border"/> : "Download"}
                                        </Button>
                                    </div>
                                </div>

                                <Table className='mb-0' striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Phone</th>
                                        <th>Red Flag</th>
                                        <th>Scores</th>
                                        <th>Products</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {detail.results && detail.results.length &&
                                    detail.results.map((report, index) => {
                                        return (<tr key={report.id}>
                                            <td>
                                                {(index + 1) + (((this.state.page || 1) - 1) * 10)}
                                            </td>
                                            <td>
                                                <ul className="list-group">
                                                    <li className="list-group-item d-flex justify-content-between
                                                             align-items-center p-0 border-0 bg-transparent">
                                                        <small>UserID: </small>
                                                        <small>
                                                            <a target="_blank"
                                                               href={`https://misters.in/en/reports/?id=${report.report_id}`}>{report.user.id}</a>
                                                        </small>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between
                                                             align-items-center p-0 border-0 bg-transparent">
                                                        <small>Phone: </small>
                                                        <small
                                                            style={{color: `${report.user.phone ? '#00a700' : '#F00'}`}}>
                                                            {report.user.phone ? 'Available' : 'Not Available'}
                                                        </small>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between
                                                             align-items-center p-0 border-0 bg-transparent">
                                                        <small>Create Date: </small>
                                                        <small>{moment(report.created_at).format('DD MMMM YYYY')}</small>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between
                                                             align-items-center p-0 border-0 bg-transparent">
                                                        <small>Create Time: </small>
                                                        <small>{moment(report.created_at).format('hh:mm:ss a')}</small>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className="list-group">
                                                    {!!report.report_sheet.redflags.length &&
                                                    report.report_sheet.redflags.map((tag) => {
                                                        return (
                                                            <li key={tag.id} className="list-group-item d-flex justify-content-between
                                                             align-items-center p-0 border-0 bg-transparent">
                                                                <small>{tag.name}</small>
                                                                <Icon size={14}
                                                                      name={tag.value ? 'check_circle_outline' : 'highlight_off'}
                                                                      color={tag.value ? '#00a700' : '#F00'}/>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className="list-group">
                                                    {!!report.report_sheet.analysisscores.length &&
                                                    report.report_sheet.analysisscores.map((score) => {
                                                        return (
                                                            <li key={score.id} className="list-group-item d-flex justify-content-between
                                                             align-items-center p-0 border-0 bg-transparent">
                                                                <small>{score.name}</small>
                                                                <small>{Math.round(score.score * 100) / 100}</small>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className="list-group">
                                                    {!!report.report_sheet.products.length &&
                                                    report.report_sheet.products.map((product) => {
                                                        return (
                                                            <li key={product.id} className="list-group-item d-flex justify-content-between
                                                             align-items-center p-0 border-0 bg-transparent">
                                                                <small>{product.product_id}</small>
                                                                <small>{product.title}</small>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </Table>
                                <div className="row pt-4">
                                    <div className="col-12">
                                        {detail.results && detail.results.length &&
                                        this.renderPagination()}
                                    </div>
                                </div>
                            </CardContainer>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    reportsheets: state.dashboard.reportsheets,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchReportSheets,
    fetchReportSheet,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Dashboard);
