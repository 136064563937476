import React from 'react';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Button, ButtonGroup, Form, InputGroup, Table} from "react-bootstrap";

import MainNavbar from "../../components/navbars/MainNavbar";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import {fetchSalesDatas} from "../../actions/salesdata/salesdata"
import {downloadCSVFromJson, getPrice, getSearchParams} from "../../utils";
import {scrollToTop} from "../../constants";
import PaginationComponent from "../../components/others/PaginationComponent";
import SalesDataEditCreateModal from "../../components/modals/SalesDataEditCreateModal";


class SalesData extends React.Component {

    static propTypes = {
        salesdata: PropTypes.object,
        fetchSalesDatas: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {page = 1} = getSearchParams(search);

        this.state = {
            showSalesDataModal: false,
            sales: {},
            page,
        };
    }

    componentDidMount() {
        let {page} = this.state;
        this.props.fetchSalesDatas({page});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {detail, isFetching, isUpdating, isCreating, isDeleting, error, retry} = this.props.salesdata;

        let {search, page} = this.state;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);

        if (!isFetching && prevProps.salesdata.isFetching && !error && !retry) {
            scrollToTop();
        }

        if ((!isUpdating && prevProps.salesdata.isUpdating && !error && !retry) ||
            (!isCreating && prevProps.salesdata.isCreating && !error && !retry) ||
            (!isDeleting && prevProps.salesdata.isDeleting && !error && !retry)) {
            this.props.fetchSalesDatas({page});
            this.setState({showSalesDataModal: false, sales: {}})
        }

        if (page !== prevState.page) {
            this.props.fetchSalesDatas({page});
        }

        if (prevQueryParams.page !== queryParams.page) {
            this.setState({
                page: parseInt(queryParams.page || "1"),
            });
        }
    }

    handlePageClick = (page) => {
        this.setState({page});
        this.props.history.push(`/sales-data/?page=${page}`);
    };

    render() {
        let {salesdata: {list: {results = [], count}, isFetching}} = this.props;
        let {page, showSalesDataModal, sales} = this.state;

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Sales Data'}/>

                <SalesDataEditCreateModal show={showSalesDataModal} sales={sales}
                                          handleClose={() => this.setState({showSalesDataModal: false, sales: {}})}/>

                <div className="container-fluid">
                    <div className="row pt-3">
                        <div className="col-12 text-right">
                            <Button variant="success" size={'sm'}
                                    onClick={() => this.setState({showSalesDataModal: true})}>
                                Create
                            </Button>
                        </div>
                        <div className="col-12 pt-4">
                            <Table responsive striped bordered hover cellPadding={3}>
                                <thead>
                                <tr>
                                    <th>Channel</th>
                                    <th>Seller name</th>
                                    <th>Sales count</th>
                                    <th>Sales amount</th>
                                    <th>Timestamp</th>
                                    <th>Created at</th>
                                    <th>Updated at</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.map(
                                        (sales) => (
                                            <tr key={sales.id}>
                                                <td className="order-cid">{sales.channel}</td>
                                                <td className="order-cid">{sales.seller_name}</td>
                                                <td className="order-cid">{sales.sales_count}</td>
                                                <td className="order-cid">{getPrice(sales.sales_amount)}</td>
                                                <td className='order-date'>
                                                    {moment(sales.timestamp).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                                <td className='order-date'>
                                                    {moment(sales.created_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                                <td className='order-date'>
                                                    {moment(sales.updated_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                                <td>
                                                    <ButtonGroup vertical className="action-group">
                                                        <Button variant="primary" size={'sm'}
                                                                onClick={() => this.setState({
                                                                    showSalesDataModal: true,
                                                                    sales
                                                                })}>
                                                            Edit
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-12 py-3">
                            {!!count &&
                            <PaginationComponent page={parseInt(page)} count={parseInt(count)} perPage={1}
                                                 onClick={this.handlePageClick}/>}
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    salesdata: state.salesdata.salesdata,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchSalesDatas,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(SalesData);
