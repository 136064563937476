import {call, put} from 'redux-saga/effects';
import {
    USER_LOGIN_RESULT,
    USER_LOGIN_ERROR,
    USER_LOGIN_RETRY,
} from '../../actions/accounts/login';
import api from '../api';
import {ACCESS, ACCESS_EXPIRE, ACCESS_EXPIRE_TIME, REFRESH, USER} from "../makeRequest";
import {isUserAnalyst} from "../../permissions";
import {setStorageItem} from "../../utils";
import moment from "moment";


export function* userLogin(action) {
    try {
        // const {phoneNumber, otp} = action;
        const response = yield call(api.accounts.postUserLogin, action.data);
        const result = yield response.json();
        console.warn('SAGA:userLogin', action, result);

        if (response.status === 200) {
            if (isUserAnalyst(result.user)) {
                yield setStorageItem(ACCESS, result.token.access);
                yield setStorageItem(USER, JSON.stringify(result.user));
                yield setStorageItem(REFRESH, result.token.refresh);
                yield setStorageItem(ACCESS_EXPIRE, moment().add(ACCESS_EXPIRE_TIME.value, ACCESS_EXPIRE_TIME.unit));
                yield put({type: USER_LOGIN_RESULT, data: result});
            }
            else {
                yield put({type: USER_LOGIN_ERROR, error: true, message: "You are not an analyst"});
            }
        }
        else {
            yield put({type: USER_LOGIN_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: USER_LOGIN_RETRY, retry: true, message: e.message});
    }
}
