import {
    FETCH_REPORT_SHEETS_FIELDS,
    FETCH_REPORT_SHEETS_FIELDS_RESULT,
    FETCH_REPORT_SHEETS_FIELDS_ERROR,
    FETCH_REPORT_SHEETS_FIELDS_RETRY,

    FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE,
    FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RESULT,
    FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_ERROR,
    FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RETRY,

} from '../../actions/dashboard/reportsheetsfields';


const initialState = {
    detail: {},
    list: [],
    isFetchingList: false,
    isFetching: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_REPORT_SHEETS_FIELDS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_REPORT_SHEETS_FIELDS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_REPORT_SHEETS_FIELDS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_REPORT_SHEETS_FIELDS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};


        default:
            return {...state};
    }

};

export default reducer;
