import {
    FETCH_SALES_DATAS,
    FETCH_SALES_DATAS_RESULT,
    FETCH_SALES_DATAS_ERROR,
    FETCH_SALES_DATAS_RETRY,

    FETCH_SALES_DATA,
    FETCH_SALES_DATA_RESULT,
    FETCH_SALES_DATA_ERROR,
    FETCH_SALES_DATA_RETRY,

    CREATE_SALES_DATA,
    CREATE_SALES_DATA_RESULT,
    CREATE_SALES_DATA_ERROR,
    CREATE_SALES_DATA_RETRY,

    UPDATE_SALES_DATA,
    UPDATE_SALES_DATA_RESULT,
    UPDATE_SALES_DATA_ERROR,
    UPDATE_SALES_DATA_RETRY,

    DELETE_SALES_DATA,
    DELETE_SALES_DATA_RESULT,
    DELETE_SALES_DATA_ERROR,
    DELETE_SALES_DATA_RETRY,
} from '../../actions/salesdata/salesdata';


const initialState = {
    detail: {},
    list: {},
    isFetchingList: false,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_SALES_DATAS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_SALES_DATAS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_SALES_DATAS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_SALES_DATAS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_SALES_DATA:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_SALES_DATA_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_SALES_DATA_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_SALES_DATA_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case CREATE_SALES_DATA:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_SALES_DATA_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_SALES_DATA_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_SALES_DATA_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case UPDATE_SALES_DATA:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_SALES_DATA_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_SALES_DATA_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_SALES_DATA_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case DELETE_SALES_DATA:
            return {...state, isDeleting: true, error: false, message: null, retry: false};
        case DELETE_SALES_DATA_RESULT:
            return {...state, isDeleting: false};
        case DELETE_SALES_DATA_ERROR:
            return {...state, isDeleting: false, error: true, message: action.message};
        case DELETE_SALES_DATA_RETRY:
            return {...state, isDeleting: false, retry: true, message: action.message};

        default:
            return {...state};
    }

};

export default reducer;
