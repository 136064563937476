
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_RESULT = 'USER_LOGIN_RESULT';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_RETRY = 'USER_LOGIN_RETRY';


export const userLogin = (data) => ({
    type: USER_LOGIN,
    data,
});
