import {call, put} from 'redux-saga/effects';
import {
    FETCH_REPORT_SHEETS_FIELDS_RESULT,
    FETCH_REPORT_SHEETS_FIELDS_ERROR,
    FETCH_REPORT_SHEETS_FIELDS_RETRY,

    FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RESULT,
    FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_ERROR,
    FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RETRY,

} from '../../actions/dashboard/reportsheetsfields';
import api from '../api';

export function* fetchReportSheetsFields(action) {
    try {
        const response = yield call(api.dashboard.reportsheetsfields.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchReportSheetsFields', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REPORT_SHEETS_FIELDS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_REPORT_SHEETS_FIELDS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPORT_SHEETS_FIELDS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchReportSheetsFieldsPercentage(action) {
    try {
        const response = yield call(api.dashboard.reportsheetsfields.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchReportSheetsFieldsPercentage', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RETRY, retry: true, message: e.message});
    }
}
