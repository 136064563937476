import store from "./store";

export const ANALYST = 'analyst';

export const checkRole = (role, user = store.getState().accounts.login.detail) => {
    return (user.permissions.roles || []).includes(role);
};

export const isUserAnalyst = (user = store.getState().accounts.login.detail) => {
    return checkRole(ANALYST, user);
};