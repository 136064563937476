import {
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_RESULT,
    UPDATE_USER_PROFILE_ERROR,
    UPDATE_USER_PROFILE_RETRY,
    FETCH_USER_PROFILE,
    FETCH_USER_PROFILE_RESULT,
    FETCH_USER_PROFILE_ERROR,
    FETCH_USER_PROFILE_RETRY,
} from '../../actions/accounts/profile';


const initialState = {
    data: {},
    isFetching: false,
    isUpdating: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    // console.warn(action, state);
    switch (action.type) {
        case UPDATE_USER_PROFILE:
            return {...state, isUpdating: true, error: false, message: null, retry: false,};
        case UPDATE_USER_PROFILE_RESULT:
            return {...state, isUpdating: false, data: {...action.data}};
        case UPDATE_USER_PROFILE_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_USER_PROFILE_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case FETCH_USER_PROFILE:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_USER_PROFILE_RESULT:
            return {...state, isFetching: false, data: {...action.data}};
        case FETCH_USER_PROFILE_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_USER_PROFILE_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};
        default:
            return {...state};
    }

};

export default reducer;
