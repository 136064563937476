import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import * as PropTypes from "prop-types";

import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";


export default class MainNavbar extends React.Component {

    static propTypes = {
        pageName: PropTypes.string,
    };

    static defaultProps = {
        pageName: 'Man Factorial',
    };

    render() {

        return (
            <Navbar expand="lg">
                <Navbar.Brand href="javascript:void(0)" className='text-capitalize'>{this.props.pageName}</Navbar.Brand>
                {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
                {/*<Navbar.Collapse id="basic-navbar-nav">*/}
                {/*    <Nav className="mr-auto">*/}
                {/*        <Nav.Link href="#home">Home</Nav.Link>*/}
                {/*        <Nav.Link href="#link">Link</Nav.Link>*/}
                {/*        <NavDropdown title="Dropdown" id="basic-nav-dropdown">*/}
                {/*            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                {/*            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                {/*            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                {/*            <NavDropdown.Divider />*/}
                {/*            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
                {/*        </NavDropdown>*/}
                {/*    </Nav>*/}
                {/*    <Form inline>*/}
                {/*        <FormControl type="text" placeholder="Search" className="mr-sm-2" />*/}
                {/*        <Button variant="outline-success">Search</Button>*/}
                {/*    </Form>*/}
                {/*</Navbar.Collapse>*/}
            </Navbar>
        )
    }
}
