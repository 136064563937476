// External Imports
import React from "react";
// My Imports
import Login from "./screens/Login";
import Logout from "./screens/Logout";
import Dashboard from "./screens/Dashboard"
import OrdersDashbaord from "./screens/OrdersDashbaord"
import SalesData from "./screens/SalesData"


export default [
    {
        exact: true,
        path: '/',
        component: Dashboard,
    },
    {
        exact: true,
        path: '/ordersdashboard',
        component: OrdersDashbaord,
    },
    {
        exact: true,
        path: '/sales-data',
        component: SalesData,
    },
    {
        exact: true,
        path: '/login',
        component: Login,
    },
    {
        exact: true,
        path: '/logout',
        component: Logout,
    },

];

