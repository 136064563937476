// External Imports
import {combineReducers} from 'redux';
import {connectRouter, routerMiddleware, } from 'connected-react-router';
// My Imports
import accounts from './accounts';
import dashboard from './dashboard';
import salesdata from './salesdata';


const reducers = (history) => combineReducers({
    router: connectRouter(history),
    accounts,
    dashboard,
    salesdata,
});


export default reducers;
