//External Imports
import {combineReducers} from 'redux';
//My Imports
import reportsheets from './reportsheets';
import reportsheetsfields from './reportsheetsfields'


export default combineReducers({
    reportsheets,
    reportsheetsfields,
})
