import {
    USER_LOGIN,
    USER_LOGIN_RESULT,
    USER_LOGIN_ERROR,
    USER_LOGIN_RETRY,
} from '../../actions/accounts/login';


const initialState = {
    detail: {},
    isFetchingLogin: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {

    switch (action.type) {
        case USER_LOGIN:
            return {...state, isFetchingLogin: true, error: false, message: null, retry: false,};
        case USER_LOGIN_RESULT:
            return {...state, isFetchingLogin: false, detail: action.data,};
        case USER_LOGIN_ERROR:
            return {...state, isFetchingLogin: false, error: true, message: action.message};
        case USER_LOGIN_RETRY:
            return {...state, isFetchingLogin: false, retry: true, message: action.message};
        default:
            return state;
    }

};

export default reducer;
