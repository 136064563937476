/* global require */

export const DEBUG = process.env.REACT_APP_DEBUG == "true";
export const HOST = process.env.REACT_APP_HOST;
export const NODE_SERVER_HOST = process.env.REACT_APP_NODE_SERVER_HOST;


export const getMessages = (message) => {
    let messages = [];
    if (typeof message === 'string' && message.length > 0) {
        messages.push(message);
    }
    if (typeof message === 'object' && message) {
        let objectMessages = Object.values(message);
        objectMessages.map(
            msg => {
                if (typeof msg === "string") {
                    messages = [...messages, msg];
                } else {
                    messages = [...messages, ...msg];
                }
            }
        );
    }
    return messages;
};

export const getRupeeFromPaisa = (paisa) => parseFloat(paisa) / 100;

export const fieldTypes = {
    TEXT: 'text',
    INTEGER: 'integer',
    FLOAT: 'float',
    BOOL: 'bool',
    FILE: 'file',
    CHOICE: 'choice',
    MULTIPLE_CHOICE: 'multiple_choice',
    READ_ONLY_TEXT: 'read_only_text',
    DATE: 'date',
};

export const documentTypes = {
    REPORT: 'report',
    PRESCRIPTION: 'prescription',
    VITAL: 'vital',
    NOTE: 'note',
};

export const Images = {
    iconNote: '/assets/images/icon_note.png',
    iconPrescription: '/assets/images/icon_prescription.png',
    iconReport: '/assets/images/icon_report.png',
    iconVital: '/assets/images/icon_vital.png',
    logo: '/assets/images/logo.jpeg',
    phone: '/assets/images/phone.png',
    shoppingCart: '/assets/images/shopping-cart.png',
    language: '/assets/images/internet.png',
    backArrow: '/assets/images/back-arrow.svg',
    user: '/assets/images/user.png',
    rupee: '/assets/images/rupee.png',
    emptyCart: '/assets/images/empty_cart.png',
    pdGraph: '/assets/images/pd.png',
    stamp: '/assets/images/stamp.png',
    closedBox: '/assets/images/closed_box.png',
    discreteBox: '/assets/images/discrete_box.png',
    bottles: '/assets/images/bottles.jpg',
    package: '/assets/images/package.png',
    freeDelivery: '/assets/images/free-delivery.png',
    stethoscope: '/assets/images/stethoscope.png',
    dayCalender: '/assets/images/day-fifteen-calendar.png',
};

export const scrollToTop = (scrollDuration) => {
    const scrollHeight = window.scrollY;
    const scrollStep = Math.PI / (scrollDuration / 15);
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin;
    let scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
            scrollCount = scrollCount + 1;
            scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
            window.scrollTo(0, (scrollHeight - scrollMargin));
        } else clearInterval(scrollInterval);
    }, 15);
};


export const TEXT = 'text';
export const INTEGER = 'integer';
export const FLOAT = 'float';
export const BOOL = 'bool';
export const FILE = 'file';
export const CHOICE = 'choice';
export const MULTIPLE_CHOICE = 'multiple_choice';
export const READ_ONLY_TEXT = 'read_only_text';
export const DATE = 'date';
export const LOADING = 'loading';
export const HEIGHT = 'height';

export const FIELD_LABELS = {
    [TEXT]: 'Text',
    [INTEGER]: 'Integer',
    [FLOAT]: 'Float',
    [BOOL]: 'Boolean',
    [FILE]: 'File',
    [CHOICE]: 'Choice',
    [MULTIPLE_CHOICE]: 'Multiple Choice',
    [READ_ONLY_TEXT]: 'Read Only Text',
    [DATE]: 'Date',
    [LOADING]: 'Loading',
    [HEIGHT]: 'Height',
};

export const FIELD_TYPES = [
    {label: 'Text', value: TEXT},
    {label: 'Integer', value: INTEGER},
    {label: 'Float', value: FLOAT},
    {label: 'Boolean', value: BOOL},
    {label: 'File', value: FILE},
    {label: 'Choice', value: CHOICE},
    {label: 'Multiple Choice', value: MULTIPLE_CHOICE},
    {label: 'Read Only Text', value: READ_ONLY_TEXT},
    {label: 'Date', value: DATE},
    {label: 'Loading', value: LOADING},
    {label: 'Height', value: HEIGHT},
];

export const languageOptions = [
    {label: 'Hindi', value: 'hindi'},
    {label: 'English', value: 'english'},
    {label: 'Bengali', value: 'bengali'},
    {label: 'Marathi', value: 'marathi'},
    {label: 'Tamil', value: 'tamil'},
    {label: 'Telugu', value: 'telugu'},
    {label: 'Gujarati', value: 'gujarati'},
];

export const colors = [
    '#FF6600',
    '#C3DE02',
    '#FF0000',
    '#0E7909',
    '#FF9900',
    '#dfbe00',
    '#6EAF05',
    '#d8d400',
    '#6a5acd',
    '#0000ff',
];

export const TOTAL_SCORE = 'total_score';
export const PROBLEM_SCORE = 'problem_score';


export const zTableX = [-3.4, -3.3, -3.2, -3.1, -3, -2.9, -2.8, -2.7, -2.6, -2.5, -2.4, -2.3, -2.2, -2.1, -2, -1.9, -1.8, -1.7, -1.6, -1.5, -1.4, -1.3, -1.2, -1.1, -1, -0.9, -0.8, -0.7, -0.6, -0.5, -0.4, -0.3, -0.2, -0.1, 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3, 3.1, 3.2, 3.3, 3.4];
