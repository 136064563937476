export const FETCH_REPORT_SHEETS_FIELDS = 'FETCH_REPORT_SHEETS_FIELDS';
export const FETCH_REPORT_SHEETS_FIELDS_RESULT = 'FETCH_REPORT_SHEETS_FIELDS_RESULT';
export const FETCH_REPORT_SHEETS_FIELDS_ERROR = 'FETCH_REPORT_SHEETS_FIELDS_ERROR';
export const FETCH_REPORT_SHEETS_FIELDS_RETRY = 'FETCH_REPORT_SHEETS_FIELDS_RETRY';

export const FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE = 'FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE';
export const FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RESULT = 'FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RESULT';
export const FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_ERROR = 'FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_ERROR';
export const FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RETRY = 'FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE_RETRY';


export const fetchReportSheetsFields = (data) => ({
    type: FETCH_REPORT_SHEETS_FIELDS,
    data
});

export const fetchReportSheetsFieldsPercentage = (data) => ({
    type: FETCH_REPORT_SHEETS_FIELDS_PERCENTAGE,
    data
});
