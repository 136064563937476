import {call, put} from 'redux-saga/effects';
import {
    FETCH_REPORT_SHEETS_RESULT,
    FETCH_REPORT_SHEETS_ERROR,
    FETCH_REPORT_SHEETS_RETRY,

    FETCH_REPORT_SHEET_RESULT,
    FETCH_REPORT_SHEET_ERROR,
    FETCH_REPORT_SHEET_RETRY,
} from '../../actions/dashboard/reportsheets';
import api from '../api';

export function* fetchReportSheets(action) {
    try {
        const response = yield call(api.dashboard.reportsheets.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchReportSheets', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REPORT_SHEETS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_REPORT_SHEETS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPORT_SHEETS_RETRY, retry: true, message: e.message});
    }
}

export function* fetchReportSheet(action) {
    try {
        const response = yield call(api.dashboard.reportsheets.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchReportSheet', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REPORT_SHEET_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_REPORT_SHEET_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPORT_SHEET_RETRY, retry: true, message: e.message});
    }
}
