import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import SidebarItem from './SidebarItem';
import SidebarHeader from './SidebarHeader';
import './styles.scss';
import {withRouter} from "react-router";


class MainSidebar extends PureComponent {

    static propTypes = {
        history: PropTypes.object,
    };

    static defaultProps = {
        active: false,
    };

    handleLogoutClick = () => {
        this.props.history.push('/logout');
    };

    render() {
        const path = this.props.history.location.pathname;
        // let user = localStorage.getItem('user');
        // try {user = JSON.parse(user);}
        // catch (e) {user = {};}
        // console.warn(user);

        return (
            <div className={'sidebar ' + (this.props.active ? 'active' : '')}>
                <i onClick={this.props.toggleSidebar}
                   className={'material-icons sidebar-toggle ' + (this.props.active ? 'active' : '')}>keyboard_arrow_right</i>

                <ul className='sidebar-items'>
                    <SidebarHeader type={'Misters'}/>

                    <SidebarItem icon='list_alt'
                                 type='Quiz Dashboard'
                                 active={path === '/'}
                                 onClick={() => this.props.history.push('/')}/>

                    <SidebarItem icon='list_alt'
                                 type='Orders Dashboard'
                                 active={path === '/ordersdashboard'}
                                 onClick={() => this.props.history.push('/ordersdashboard')}/>

                    <SidebarItem icon='list_alt'
                                 type='Sales data'
                                 active={path === '/sales-data'}
                                 onClick={() => this.props.history.push('/sales-data')}/>

                    <SidebarItem icon='lock'
                                 type='Logout'
                                 active={path === '/logout'}
                                 onClick={this.handleLogoutClick}/>
                </ul>
            </div>
        )
    }
}

export default withRouter(MainSidebar);
